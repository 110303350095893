import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'
import BlockQuote from '../../components/blockquote'


const characters = [
  {name: 'Don Giovanni', voice: 'Baritone', desc: 'The title character, our anti-hero, a nobleman with few restraints. Fundamentally a complete scumbag but rather a charming one.'},
  {name: 'Leporello', voice: 'Bass', desc: 'Giovanni’s much put upon servant. Extremely likeable but entirely complicit.'},
  {name: 'Il Commendatore (Don Pedro)', voice: 'Bass', desc: 'The father of Donna Anna and a powerful man. He dies quickly but ultimately gets his revenge.'},
  {name: 'Donna Anna', voice: 'Soprano', desc: 'A noblewoman engaged to Don Ottavio. A victim of Giovanni.'},
  {name: 'Don Ottavio', voice: 'Tenor', desc: 'Donna Anna’s fiancée. He’s a bit of damp squib really but he gets some lovely music.'},
  {name: 'Donna Elvira', voice: 'Soprano', desc: 'Another noble victim of Giovanni. A trifle batty but also one of the most complex and interesting characters craving both revenge and love.'},
  {name: 'Masetto', voice: 'Bass', desc: 'A sweet if none too bright peasant engaged to Zerlina.'},
  {name: 'Zerlina', voice: 'Soprano', desc: 'A peasant girl engaged to Masetto. Preyed upon by Giovanni.'},
  ];

const inbrief = {name: 'Don Giovanni', composer: "Wolfgang Amadeus Mozart", librettist: "Lorenzo Da Ponte", language: "Italian", date: 'October 29th, 1787', acts: "Two", musiclength: "Two hours, fifty five minutes"};

const bibliography = [
  {name: 'Mozart The Reign of Love', writer: 'Jan Swafford', url: 'https://amzn.to/2T2fLIO'},
  {name: 'Stories of the Great Operas & their Composers', writer: 'Ernest Newman', url: 'https://amzn.to/2T3IjBt'},
  {name: 'Cambridge Opera Handbooks, Don Giovanni', writer: 'Julian Rushton', url: 'https://amzn.to/3vY69RY'},
  {name: 'The Librettist of Venice', writer: 'Rodney Bolt', url: 'https://amzn.to/3w1wE91'},
  {name: 'The Making of an Opera, Don Giovanni at Glyndebourne', writer: 'John Higgins', url: 'https://amzn.to/313ilFx'},
  ]

const ShawQuote = {content: 'Why not leave Don Giovanni in peace on the shelf? It is so easy not to perform it.', quotee: 'G. B. Shaw'}

// markup
const GiovanniPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Don Giovanni | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/giovanni" />
          <meta name="description" content="A guide to Mozart's masterpiece, Don Giovanni. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="Don Giovanni" subtitle="An Opera by Wolfgang Amadeus Mozart"/>
        <MainBodySection>

          <p>Mozart and Da Ponte’s <i>Don Giovanni</i> is one of the mainstays of the operatic repertory and with good reason! It’s a triumphant retelling of the Don Juan legend capturing all the thrill of an adventure with a dash of comedy, a dose of tragedy and a challengingly dark underpinning.</p>

          <p>The score is first-rate, a stream of fabulous arias that are not only wonderful songs but also capture the depth of each character. One of Mozart’s last operas, he uses various musical forms to advance the plot, almost completely abandoning the stagnant <Link to="/operaabc/arias">da capo form</Link> that was the hallmark of opera in earlier years (though he would return to them in his later <i>La Clemenza di tito</i>). We’re still in relatively formal territory relative to what would come in the 19th Century, but much of the repetition is gone, allowing for the rapid advancement of the story and far more character exploration.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>One of the most impressive aspects of Mozart’s music is his ability to keep things coherent whilst tackling such a range of emotions, often at the same time. One of the most stunning scenes concludes Act I when Mozart uses a combination of dances to reflect the different plot threads: Leporello waltzes a seriously mad Masetto away, Giovanni whisks Zerlina away with a contredanse, and the masked nobles watch on to a minuet. Briefly and quite stunningly, Mozart has all these dances going at once, their three times (3/4 , 2/4, 3/8) all fitting together with the emotions soaring in parallel.</p>

          <p><i>Giovanni</i> has become one of the most lauded operas in history. When asked which of his operas Gioachino Rossini liked the best he responded: “Don Giovanni”! Whether you find it to be your favourite opera remains to be seen, but there’s no denying it’s a true masterpiece!</p>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 90 mins</SynopsisAct>

            <h4>Scene 1</h4>

            <ImageFull>
              <StaticImage src="../../images/operas/giovanni/giovanni_opening_met_marty_sohl.jpg" alt="Simon Keenlyside and Hibla Gerzmava in the opening scene at the Metropolitan Opera, photo Marty Sohl"/>
              <figcaption>Simon Keenlyside and Hibla Gerzmava in the opening scene at the Metropolitan Opera &copy; Marty Sohl/Met</figcaption>
            </ImageFull>

            <p>We find ourselves outside the Commendatore’s house. Giovanni is inside attempting to rape Donna Anna whilst Leporello awaits his return, moaning about his place in life (somewhat justifiably!). Giovanni emerges masked and fleeing Anna, she holds him back, but he escapes. The Commendatore comes running, challenging Giovanni to a duel. This goes badly for him; Giovanni quickly kills him with his sword before escaping with Leporello. Anna returns with Don Ottavio, her fiancée, and both are stunned to find the dead Commendatore. At the forcing of Anna, Ottavio swears vengeance on the unknown attacker.</p>

            <h4>Scene 2</h4>

            <p>Leporello asks Giovanni if he can speak honestly. Giovanni says yes but still gets angry when Leporello then tells him he’s living the life of a scoundrel. Giovanni then 'smells' an attractive woman (read into this what you will, Leporello is impressed). They overhear her yelling about seeking revenge on the lover who has betrayed her. Giovanni moves in to flirt, but there’s consternation all around when both he and the lady, Donna Elvira, recognise each other. Giovanni is, in fact, the lover she is ranting about!</p>

            <p>Giovanni has Leporello intercept her and runs off. Leporello implores her that Giovanni is not worthy of her. Giovanni is faithful to no one, and Leporello then rattles off the tremendous list of Giovanni’s conquests in one of the great arias from this opera, "Madamina, il catalogo è questo" – "My dear lady, this is the catalogue".</p>

            <VideoPlayer src="https://www.youtube.com/embed/uJjHVTCm4HE?autoplay=1" id="uJjHVTCm4HE" title="Ildebrando D'Arcangelo sings 'Madamina, il catalogo è questo'"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <h4>Scene 3</h4>

            <p>The air lightens as a joyous marriage procession enters. However, it is not long before Giovanni and Leporello arrive, and Giovanni instantly targets the bride Zerlina. Her fiancé Masetto is less than pleased, so Giovanni has Leporello offer to move the wedding festivities to Giovanni’s palace. Masetto becomes extremely jealous when he realises that Giovanni is planning on staying behind with Zerlina. He sings a terrific aria, "Ho capito! Signor, sì" – "I understand! Yes, my lord!" before being forced to leave by Leporello.</p>

            <VideoPlayer src="https://www.youtube.com/embed/9VjlOYi0dVc?start=73&autoplay=1" id="9VjlOYi0dVc" title="David Bizic sings 'Ho capito, signor sì!'"/>

            <p>Left alone, Giovanni begins his seduction when Elvira bursts on the scene and rapidly flees with Zerlina in tow. Anna and Ottavio arrive plotting vengeance on the still unknown killer of the Commendatore. Utterly unaware that Giovanni is that killer, they ask for his help. He promises to do so, delighted to be unrecognised, but Elvira comes back before they can discuss anything, and quickly she sets about convincing Anna and Ottavio that Giovanni is up to no good. Giovanni in turn tries to convince them that Elvira is off her rocker.</p>

            <p>Giovanni leaves, but in his departure Anna suddenly recognises him as her attacker! She describes the attack to Ottavio and again demands he avenge her. Depending on the version of the opera, Ottavio may or may not get at this point the rather lovely aria "Dalla sua pace la mia dipende" – "On her peace my peace depends".</p>

            <p>Leporello updates Giovanni to all the goings-on, that he successfully distracted Masetto until Zerlina, appearing with Elvira, ruined things. Giovanni remains perpetually delighted by all this, telling Leporello to organise a party and invite all the girls with the absolute treat (and incredibly short) Champagne aria: "Fin ch'han dal vino calda la testa" – "Till they are tipsy".</p>

            <VideoPlayer src="https://www.youtube.com/embed/CUOWb40LIuw?start=2&autoplay=1" id="CUOWb40LIuw" title="Mariusz Kwiecień sings the Champagne Aria"/>

            <h4>Scene 4</h4>

            <p>We are now outside Giovanni’s home. Zerlina finds Masetto and calms his jealousy. Giovanni's voice is heard, and Masetto hides, hoping to see for himself what’s going on between Zerlina and Giovanni. Zerlina tries to hide, but Giovanni finds her and continues his seduction, rapidly thwarted by Masetto’s reappearance. Giovanni is surprised but rapidly recovers his wits and leads them off into his home. Ottavio, Anna, Elvira arrive in masked disguise. From a balcony, Leporello invites them to the party. They accept the invitation, Ottavio and Anna praying while Elvira swears vengeance.</p>

            <h4>Scene 5</h4>

            <p>The party is in full swing. Leporello distracts Masetto while Giovanni again attempts to have his way, leading Zerlina away from the party. Zerlina screams for help, Giovanni tries to blame Leporello, fooling no one. Ottavio, Elvira, and Anna reveal themselves, but Giovanni escapes again in the tremendous Act 1 finale!</p>

            <VideoPlayer src="https://www.youtube.com/embed/tCfbp7GDrrw?autoplay=1" id="tCfbp7GDrrw" title="The Act I finale from the Metropolitan Opera's 2016 revival"/>
          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 85 mins</SynopsisAct>

            <h4>Scene 1</h4>

            <p>Back in the streets, Leporello threatens that he’s done serving Giovanni, but he is quickly assuaged with more money thrown his way. The ever lecherous Giovanni has decided to seduce Elvira's maid and thinking he’ll have more luck in lower-class clothes, Giovanni orders Leporello switch clothing with him. Elvira comes to her window and never one to pass up a bit of fun, Giovanni sends Leporello out, visible to her wearing Giovanni’s clothing. Giovanni speaks, convincing her he wants her back, while Leporello mimes his actions. Elvira is convinced... Leporello, draws her away so Giovanni can now serenade her maid.</p>

            <p>Before he’s gotten very far, Masetto and his gaggle of friends arrives, hoping to capture and kill Giovanni. Giovanni poses as Leporello and tags along with them. Giovanni separates the none too bright Masetto from his friends, beats him up, and runs off. Zerlina appears and consoles the beaten Masetto.</p>

            <h4>Scene 2</h4>

            <p>Leporello manages to evade Elvira. However, during his escape, he meets Ottavio and Anna, rapidly followed by Zerlina and Masetto. As he’s still wearing Giovanni’s clothes they prepare to kill him. Elvira tries to protect him, but Leporello takes off Giovanni’s cloak and reveals his true identity as they bear down on him. He begs for mercy and, seeing an opportunity, runs off. Ottavio is now convinced that Giovanni is the one and swears vengeance. Depending on the version, he gets another lovely aria here: "Il mio tesoro" – "My treasure”.</p>

            <p>In rare versions, there’s a brief scene where Zerlina catches Leporello again, but it’s so unusual to see it done and totally unimportant to the overall plot that we’ll leave it as a surprise if you ever happen upon it!</p>

            <h4>Scene 3</h4>

            <p>Giovanni wanders into a graveyard where he meets Leporello. They are surprised to hear a statue talking. Giovanni forces Leporello to read the statue's base, which they realise is the Commendatore’s grave. Giovanni invites the statue to dinner. Remarkably, and to the horror of both, the statue nods its head.</p>
            
            <VideoPlayer src="https://www.youtube.com/embed/rPcLcB6pxTc?autoplay=1" id="rPcLcB6pxTc" title="The statue speaks, Vienna State Opera 1999"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <h4>Scene 4</h4>

            <p>Ottavio somewhat patriarchally pressures Anna to marry him. She says no, so soon after her father’s death. He calls her cruel, but she states that she loves him and is faithful.</p>

            <h4>Scene 5</h4>

            <p>Don Giovanni indulges in a luxurious meal served by Leporello. Elvira arrives in a last-ditch effort to have him change his ways. Don Giovanni taunts her, repelling her suggestions, and Elvira leaves in a mild rage. Offstage, she can be heard screaming in terror. Giovanni sends Leporello to see what is happening, and he is also heard to cry… the statue has come! A terrifying knocking begins, and Giovanni opens the door to reveal the Commendatore as a statue. It offers Giovanni a final opportunity to repent, which he refuses. Giovanni cries out in pain and terror as he is taken down to hell (plenty of opportunities here for Directors and Designers to unleash their imaginations).</p>

            <ImageFull>
              <StaticImage src="../../images/operas/giovanni/giovanni_finale_met_marty_sohl.jpg" alt="Ildar Abdrazakov as Giovanni, descending to hell at the Metropolitan Opera"/>
              <figcaption>Ildar Abdrazakov as Giovanni, descending to hell at the Metropolitan Opera &copy; Marty Sohl/Met</figcaption>
            </ImageFull>

            <p>In a small epilogue of sorts, all the other major characters arrive, searching for Giovanni. They find Leporello, who makes it clear no one will ever see Don Giovanni again. The incredible final ensemble delivers the moral of the opera: "Questo è il fin di chi fa mal, e de' perfidi la morte alla vita è sempre ugual" - "Such is the end of the evildoer: the death of a sinner always reflects his life".</p>

            <VideoPlayer src="https://www.youtube.com/embed/32M-4AVqwkc?autoplay=1" id="32M-4AVqwkc" title="The final ensemble, Brussels Philharmonic"/>
          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where and When"/>

            <p><i>Don Giovanni</i> is set in Seville during the 17th Century (roughly a century before Mozart’s own time). One of the most interesting aspects of the opera is just how loosely placed it is. We get few real reference points, scenes that constantly shift locale, inside and outside, sometimes both at once. It’s both an earthy opera in true buffo style, exploring the wealthy and the poor, but also strangely dislocated into a less settled supernatural world. That’s part of the joy of the piece, but it also makes it a tricky one to stage with productions not infrequently struggling to find the balance.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/giovanni/act2_set_design_prague_1790s.jpg" alt="Act II set design Prague 1790s"/>
              <figcaption>The earliest known set design for Giovanni from Prague in the 1790s, the Act II graveyard scene</figcaption>
            </ImageFull>

            <Header title="The Origin's of Mozart's Don"/>

            <ImageRight maxwidth="180px">
              <StaticImage src="../../images/operas/giovanni/Don_Juan.jpeg" alt="Statue of Don Juan Tenorio in Seville"/>
              <figcaption>Statue of Don Juan Tenorio in Seville &copy; <a href="https://commons.wikimedia.org/wiki/File:Don_Juan_001.jpg">Anual</a></figcaption>
            </ImageRight>

            <p>Don Juan was by Mozart’s time a relatively well-known legend. His initial basis is commonly thought to be a medieval man, Don Juan Tenorio who it is said seduced the daughter of the commandant of Ulloa, then killed him and was swiftly killed in revenge by individuals who claimed that he was carried off to hell by the statue of the commandant.</p>

            <p>Don Juan hit the stage in 1630 through the moralistic play El Burlador de Sevilla y convidado de piedra (The Prankster of Seville and His Stone Guest) by Tirso de Molina, a pseudonym of a Spanish monk. The tale made its way to France via Molière, who wrote his own play in 1665 and from then on became a staple with all sort of versions, including a 1761 ballet by Gluck.</p>

            <p>Mozart and Da Ponte’s most crucial source, however, was a previous libretto by Giovanni Bertati for a Don Giovanni opera by Giuseppe Gazzaniga that premiered in early 1787. Between the earliest drafts of his memoirs and the final edition, Da Ponte suppressed this fact, but Domenico Guardasoni, a Prague impresario, likely gave Mozart a copy of Bertati’s libretto. There are significant parallels between the two texts, but Da Ponte’s libretto is far longer and contains much that is original. In a quirk of history, the first Giovanni in Gazzaniga’s opera Antonio Baglioni was the first Don Ottavio in Mozart’s!</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>George Bernard Shaw</FunFactsHeader>

            <BlockQuote quote={ShawQuote}/>

            <p>There’s self-evident reasons why we should disregard Shaw’s suggestion (likely intended somewhat ironically by Shaw himself). Still, it is true to say that Giovanni is a deceptively difficult opera to both stage and sing. It has become something of a graveyard for contemporary opera stagings caught between the two stools of naturalism and fantasy. Significantly it’s also an opera with an unusually large number of major principal roles. This fact undermined the opera at several stages of its life, including at its US premiere.</p>

            <p>In the 2017/2018 season <a href="http://operabase.com/top.cgi?lang=en&break=0&show=opera&no=10&nat="><i>Giovanni</i> was the 10th most performed opera in the world</a>. It would appear whether easy to perform or not, for audiences and opera houses it’s also very difficult not to perform!</p>

            <FunFactsHeader>Dramma Giocoso</FunFactsHeader>

            <p>Da Ponte billed <i>Don Giovanni</i> as a 'dramma giocoso' roughly translating as a 'drama with jokes'. That definition often gets bandied about as if it was hugely meaningful, but in reality, that was how Da Ponte described his text, not the opera as a whole (he called <i>Cosi Fan Tutte</i> the same). Mozart rather more straightforwardly listed it as an 'Opera Buffa'. So what does all this mean? It’s fair to say from the opening chords onwards <i>Don Giovanni</i> is no typical buffa work, but it still hews closer to that genre than anything else. Ultimately it doesn’t matter, <i>Don Giovanni</i> is a unique work and one that doesn’t need to be labelled.</p>

            <FunFactsHeader>What’s in a version?</FunFactsHeader>

            <p>Giovanni comes in 2 distinct versions, Prague 1787 and Vienna 1788, corresponding to the two earliest productions overseen by Mozart. For better (Don Ottavio gets two terrific arias instead of one), for worse (this makes it a very long evening…) these versions have frequently been blended. Mozart or Da Ponte likely never intended this combination version, but it has become the standard.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>Giovanni in the USA</FunFactsHeader>

            <p><i>Don Giovanni</i> made it to the United States some 30 years after its Prague premiere. At this time, opera was in relative infancy in the US. Manuel Garcia, a hugely successful singer, and his very musical family were coaxed to New York by Dominick Lynch, a wine merchant with money to spare, and Stephen Price now the manager of the Park Theatre where they would perform and previously Drury Lane in London. They were to bring a full Italian opera season to be dominated by the works of Rossini, and Lynch hoped they would establish a permanent company in New York.</p>

            <p>By this time, Da Ponte was living in New York (Da Ponte’s life is a fascinating one regaled quite brilliantly in <a href="https://amzn.to/3jOaLFr">Rodney Bolt’s ‘The Librettist of Venice’</a>) and his encouragement led to <i>Don Giovanni</i> finding its way into the Garcias’ season towards its end. Musically, it was not a complete success. The sheer number of parts presented some casting issues as the Garcias’ had not brought enough singers from Europe, but the one performance became ten. Alas, the Garcias time in New York proved to be limited, but the season of 9 months and 79 performances was a significant step in establishing Italian opera in America.</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default GiovanniPage
